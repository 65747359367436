@font-face {
    font-family: 'Eugenio Sans';
    src:
        url('/assets/fonts/EugenioSans-Regular.woff2') format('woff2'),
        url('/assets/fonts/EugenioSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Eugenia Headline';
    src:
        url('/assets/fonts/EugeniaHeadline-Regular.woff2') format('woff2'),
        url('/assets/fonts/EugeniaHeadline-Regular.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: 'Eugenio Sans';
    src:
        url('/assets/fonts/EugenioSans-Bold.woff2') format('woff2'),
        url('/assets/fonts/EugenioSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

.eugenia-font {
    font-family: 'Eugenia Headline', serif;
    font-style: italic;
}
